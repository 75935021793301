import { isNull, reduce, merge } from 'lodash';
import { formatDateUTC, formatToCad } from 'utils';
import { BrokenService, LeavePeriod, PurchaseCostItem } from 'interfaces';
import { Form165aData } from 'containers/requestForm/leavePeriodEmployer/form165aReducer';
import { Form165cData } from 'containers/requestForm/leavePeriodAdjustment/form165cReducer';
import {
  parseISO,
  format,
  isValid,
  add,
  getYear,
  getMonth,
  parse,
  isAfter,
  isEqual
} from 'date-fns';
import i18n from 'i18next';
import { PAY_PERIOD_OPTIONS } from 'utils/constants';

export const isOnOrAfterInLeaveEffectiveDate = (
  date: Date,
  inLeaveEffectiveDate = new Date(2026, 0, 1)
) => isAfter(date, inLeaveEffectiveDate) || isEqual(date, inLeaveEffectiveDate);

export const parseScatteredDaysString = (scatteredDays: string) =>
  scatteredDays
    .split(',')
    .map((day: string) => parse(day, 'yyyy-MM-dd', new Date()));

/**
 * Validates all leave periods if incomplete data is found for step 2 and
 * returns list of incomplete leave period intervalNumbers
 */
export const getIncompleteLeavePeriods = (leavePeriods: Array<LeavePeriod>) =>
  leavePeriods
    .filter(leavePeriod => {
      if (leavePeriod.brokenService.purchasableInd === 'Y') {
        return (
          !leavePeriod.brokenService.maxPayPeriods ||
          !leavePeriod.brokenService.priorToLeaveEarnings ||
          leavePeriod.purchaseCostList.filter(
            purchaseCostItem =>
              isNull(purchaseCostItem.service) ||
              (leavePeriod.brokenService.purchasableInd === 'Y' &&
                isNull(purchaseCostItem.earnings) &&
                isNull(purchaseCostItem.carryForwardEarnings))
          ).length > 0
        );
      }

      return (
        leavePeriod.purchaseCostList.filter(purchaseCostItem =>
          isNull(purchaseCostItem.service)
        ).length > 0
      );
    })
    .map(leavePeriod => leavePeriod.brokenService?.intervalNumber);

/**
 * isDirty check for only step 1 of leave period StepWizard
 */
export const isStep1Dirty = (
  watchedValues: {
    scatteredDays: 'Y' | 'N';
    typeOfLeaveCategory: string;
    yearOfLeave: string;
    dateLeaveStarted: string;
    dateLeaveEnded: string;
    typeOfLeave: string;
    dateLastAtWork: string;
    isOpenEndedLeave: 'Y' | 'N';
  },
  form165Data: Form165aData | Form165cData
) =>
  watchedValues.scatteredDays !== form165Data.scatteredDays ||
  watchedValues.yearOfLeave !== form165Data.yearOfLeave ||
  watchedValues.dateLeaveStarted !== form165Data.dateLeaveStarted ||
  watchedValues.dateLeaveEnded !== form165Data.dateLeaveEnded ||
  watchedValues.typeOfLeave !== form165Data.typeOfLeave ||
  watchedValues.dateLastAtWork !== form165Data.dateLastAtWork ||
  watchedValues.isOpenEndedLeave !== form165Data.isOpenEndedLeave;

export const isInLeaveFieldDirty = (
  isScatteredDays: boolean,
  watchedValues: {
    scatteredDaysList?: Array<Date>;
    periodNotWorked?: { from: Date | null; to: Date | null };
    percentageTimeWorked?: string;
    payPeriodsInLeaveYear?: string;
    contributoryEarningsPriorToLeave?: string;
    scatteredDaysService?: string;
  },
  form165Data: Form165aData | Form165cData
) => {
  if (isScatteredDays) {
    return !!(
      (watchedValues.scatteredDaysList &&
        watchedValues.scatteredDaysList?.length >= 1 &&
        watchedValues.scatteredDaysList !== form165Data.scatteredDaysList) ||
      watchedValues.percentageTimeWorked !== form165Data.percentageTimeWorked ||
      watchedValues.payPeriodsInLeaveYear !==
        form165Data.payPeriodsInLeaveYear ||
      watchedValues.contributoryEarningsPriorToLeave !==
        form165Data.contributoryEarningsPriorToLeave ||
      watchedValues.scatteredDaysService !== form165Data.scatteredDaysService
    );
  }

  return !!(
    watchedValues.percentageTimeWorked !== form165Data.percentageTimeWorked ||
    watchedValues.payPeriodsInLeaveYear !== form165Data.payPeriodsInLeaveYear ||
    watchedValues.contributoryEarningsPriorToLeave !==
      form165Data.contributoryEarningsPriorToLeave ||
    (watchedValues.periodNotWorked?.from && form165Data.periodNotWorked?.from
      ? !isEqual(
          watchedValues.periodNotWorked?.from,
          form165Data.periodNotWorked?.from
        )
      : watchedValues.periodNotWorked?.from !==
        form165Data.periodNotWorked?.from) ||
    (watchedValues.periodNotWorked?.to && form165Data.periodNotWorked?.to
      ? !isEqual(
          watchedValues.periodNotWorked?.to,
          form165Data.periodNotWorked?.to
        )
      : watchedValues.periodNotWorked?.to !== form165Data.periodNotWorked?.to)
  );
};

/**
 * Builds expected form data for step PUT call; if on step 3, merge cached form
 * step 2 data with form step 3 data
 */
export const mergeForm165Step3Payload = (
  formValues: { [key: string]: any },
  formData: Form165aData | Form165cData,
  stepWizardPage: number | null
) => ({
  ...formValues,
  ...(stepWizardPage === 3
    ? {
        contributoryEarningsPriorToLeave:
          formData.contributoryEarningsPriorToLeave,
        payPeriodsInLeaveYear: formData.payPeriodsInLeaveYear,
        purchaseCostList: merge(
          formData.purchaseCostList,
          formValues.purchaseCostList
        )
      }
    : {})
});

export const mergeForm165Step2Payload = (
  formValues: { [key: string]: any },
  formData: Form165aData | Form165cData,
  stepWizardPage: number | null
) => ({
  ...formValues,
  ...(stepWizardPage === 2
    ? {
        purchaseCostList: merge(
          formData.purchaseCostList,
          formValues.purchaseCostList
        )
      }
    : {})
});

/**
 * Generate leave period create/update request model based on given form data
 * and active leave period step
 */
export const buildCreateUpdateRequestModel = (
  data: Form165aData | Form165cData,
  addLeavePeriodStep: number | null,
  adjustLeavePeriodStep: number | null
) => {
  switch (addLeavePeriodStep ?? adjustLeavePeriodStep) {
    case 1:
      return {
        leavePeriods: [
          {
            brokenService: {
              startDate:
                data.dateLeaveStarted && data.scatteredDays === 'N'
                  ? formatDateUTC(data.dateLeaveStarted)
                  : null,
              endDate:
                data.dateLeaveEnded && data.scatteredDays === 'N'
                  ? formatDateUTC(data.dateLeaveEnded)
                  : null,
              scatteredDaysYear:
                data.yearOfLeave && data.scatteredDays === 'Y'
                  ? parseInt(data.yearOfLeave, 10)
                  : null,
              subSourceCode: data.typeOfLeave,
              openEndedLeaveInd: data.isOpenEndedLeave,
              lastDatePhysicallyAtWorkDate:
                data.typeOfLeave === 'EL' && data.dateLastAtWork
                  ? formatDateUTC(data.dateLastAtWork)
                  : null,
              waiverStartDate:
                data.typeOfLeave === 'EL' && data.dateEntitledDisability
                  ? formatDateUTC(data.dateEntitledDisability)
                  : null,
              scatteredDaysInd: data.scatteredDays
            }
          }
        ]
      };
    case 2:
    case 3:
      return {
        leavePeriods: [
          {
            brokenService: {
              maxPayPeriods:
                data.payPeriodsInLeaveYear === 'other'
                  ? data.customPayPeriodsInLeaveYear
                  : data.payPeriodsInLeaveYear,
              priorToLeaveEarnings: data.contributoryEarningsPriorToLeave
                ? parseFloat(
                    data.contributoryEarningsPriorToLeave.replace(/,/g, '')
                  )
                : null
            },
            purchaseCostList: data?.purchaseCostList?.map(
              (
                purchaseCost: {
                  creditedServiceMonths: string;
                  contributoryEarnings: string;
                  paidFollowingYear: string;
                  originalRppContributions: string;
                  rppContributions: string;
                  originalRcaContributions: string;
                  rcaContributions: string;
                },
                index: number
              ) => ({
                purchaseCostNumber: index + 1,
                service: purchaseCost.creditedServiceMonths
                  ? parseFloat(purchaseCost.creditedServiceMonths)
                  : null,
                earnings: purchaseCost.contributoryEarnings
                  ? parseFloat(
                      purchaseCost.contributoryEarnings.replace(/,/g, '')
                    )
                  : null,
                carryForwardEarnings: purchaseCost.paidFollowingYear
                  ? parseFloat(purchaseCost.paidFollowingYear.replace(/,/g, ''))
                  : null,
                costAdjustedInd: (() => {
                  if (addLeavePeriodStep === 3 || adjustLeavePeriodStep === 3) {
                    return purchaseCost?.rppContributions !==
                      purchaseCost?.originalRppContributions ||
                      purchaseCost?.rcaContributions !==
                        purchaseCost?.originalRcaContributions
                      ? 'Y'
                      : 'N';
                  }
                  return null;
                })(),
                memberRPPCost: (() => {
                  if (purchaseCost?.rppContributions) {
                    if (purchaseCost?.rppContributions === '') {
                      return '0.00';
                    }
                    return parseFloat(
                      purchaseCost?.rppContributions?.replace(/,/g, '')
                    );
                  }
                  if (purchaseCost?.originalRppContributions) {
                    return parseFloat(
                      purchaseCost?.originalRppContributions?.replace(/,/g, '')
                    );
                  }
                  return null;
                })(),
                memberRCACost: (() => {
                  if (purchaseCost?.rcaContributions) {
                    if (purchaseCost?.rcaContributions === '') {
                      return '0.00';
                    }
                    return parseFloat(
                      purchaseCost?.rcaContributions?.replace(/,/g, '')
                    );
                  }
                  if (purchaseCost?.originalRcaContributions) {
                    return parseFloat(
                      purchaseCost?.originalRcaContributions?.replace(/,/g, '')
                    );
                  }
                  return null;
                })()
              })
            )
          }
        ]
      };
    default:
      return {};
  }
};

/**
 * Retrieve mapped maxPayPeriods and assign value based on whether it is a
 * custom user entry for the corresponding fields
 */
const getMaxPayPeriods = (maxPayPeriods: number, isCustom: boolean) => {
  if (!maxPayPeriods) {
    return '';
  }

  const payPeriod =
    PAY_PERIOD_OPTIONS.find(
      period => parseInt(period.val, 10) === maxPayPeriods
    )?.val ?? null;
  if (!isCustom) {
    return payPeriod ? payPeriod.toString() : 'other';
  }
  return payPeriod ? '' : maxPayPeriods.toString();
};

const typeOfLeaveCategoryMap = {
  NL: ['TL', 'AL', 'LS'],
  DL: ['EL'],
  SL: ['I3', 'I2', 'ID', 'PL', 'OE'],
  NP: ['LY', 'ST']
};

/**
 * Common api to form data mapper for form 165a and form 165c
 */
export const mapForm165 = (
  leavePeriod: LeavePeriod | null,
  notes?: { noteToMember: string | null; noteToOmers: string | null }
): Form165cData | Form165aData => {
  const scatteredDays = leavePeriod?.brokenService?.scatteredDaysInd ?? 'N';
  const dateLastAtWork = leavePeriod?.brokenService
    ?.lastDatePhysicallyAtWorkDate
    ? format(
        parseISO(leavePeriod?.brokenService?.lastDatePhysicallyAtWorkDate),
        'MM/dd/yyyy'
      )
    : '';
  const parsedDateLastAtWork = parseISO(
    leavePeriod?.brokenService?.lastDatePhysicallyAtWorkDate ?? ''
  );

  return {
    scatteredDays,
    dateLeaveStarted:
      leavePeriod?.brokenService?.scatteredDaysInd === 'N' &&
      leavePeriod?.brokenService?.startDate
        ? format(parseISO(leavePeriod?.brokenService?.startDate), 'MM/dd/yyyy')
        : '',
    dateLeaveEnded:
      leavePeriod?.brokenService?.scatteredDaysInd === 'N' &&
      leavePeriod?.brokenService?.endDate
        ? format(parseISO(leavePeriod?.brokenService?.endDate), 'MM/dd/yyyy')
        : '',
    yearOfLeave:
      leavePeriod?.brokenService?.scatteredDaysYear?.toString() ?? '',
    typeOfLeaveCategory: reduce(
      typeOfLeaveCategoryMap,
      (acc, val, key) =>
        val.includes(leavePeriod?.brokenService?.subSourceCode ?? '')
          ? key
          : acc,
      ''
    ),
    typeOfLeave: leavePeriod?.brokenService?.subSourceCode ?? '',
    isOpenEndedLeave: leavePeriod?.brokenService?.openEndedLeaveInd ?? 'N',
    dateLastAtWork,
    dateEntitledDisability:
      dateLastAtWork?.length === 10 &&
      isValid(parsedDateLastAtWork) &&
      scatteredDays === 'N'
        ? format(
            add(
              new Date(
                getYear(parsedDateLastAtWork),
                getMonth(parsedDateLastAtWork),
                1
              ),
              {
                months: 5
              }
            ),
            'MM/dd/yyyy'
          )
        : '',
    payPeriodsInLeaveYear: getMaxPayPeriods(
      leavePeriod?.brokenService?.maxPayPeriods ?? 0,
      false
    ),
    customPayPeriodsInLeaveYear: getMaxPayPeriods(
      leavePeriod?.brokenService?.maxPayPeriods ?? 0,
      true
    ),
    contributoryEarningsPriorToLeave: leavePeriod?.brokenService
      ?.priorToLeaveEarnings
      ? formatToCad(
          leavePeriod.brokenService.priorToLeaveEarnings,
          i18n.language,
          false
        )
      : '',
    purchaseCostList:
      leavePeriod?.purchaseCostList?.map(
        (purchaseCostItem: PurchaseCostItem) => ({
          creditedServiceMonths: purchaseCostItem?.service
            ? purchaseCostItem?.service.toString()
            : '',
          contributoryEarnings: purchaseCostItem?.earnings
            ? formatToCad(purchaseCostItem.earnings, i18n.language, false)
            : '',
          paidFollowingYear: purchaseCostItem?.carryForwardEarnings
            ? formatToCad(
                purchaseCostItem.carryForwardEarnings,
                i18n.language,
                false
              )
            : '',
          rppContributions: !isNull(purchaseCostItem?.memberRPPCost)
            ? formatToCad(purchaseCostItem?.memberRPPCost, i18n.language, false)
            : '',
          originalRppContributions: !isNull(
            purchaseCostItem?.originalMemberRPPCost
          )
            ? formatToCad(
                purchaseCostItem?.originalMemberRPPCost,
                i18n.language,
                false
              )
            : '',
          rcaContributions: !isNull(purchaseCostItem?.memberRCACost)
            ? formatToCad(purchaseCostItem?.memberRCACost, i18n.language, false)
            : '',
          originalRcaContributions: !isNull(
            purchaseCostItem?.originalMemberRCACost
          )
            ? formatToCad(
                purchaseCostItem?.originalMemberRCACost,
                i18n.language,
                false
              )
            : ''
        })
      ) ?? [],
    noteToMember: notes?.noteToMember ?? '',
    noteToOmers: notes?.noteToOmers ?? ''
  };
};

export type Form165aDataToApi = {
  leavePeriods: Array<{
    brokenService: BrokenService;
    purchaseCostList: Array<PurchaseCostItem>;
  }>;
};

export const transformInLeaveDataToApi = (
  data: Form165aDataToApi,
  form165a: Form165aData,
  InLeaveStep: number,
  inLeaveEffectiveDate?: Date
) => {
  switch (InLeaveStep) {
    case 1:
      return {
        ...data,
        leavePeriods: data.leavePeriods?.map(leavePeriod => {
          const parsedStartDate =
            leavePeriod.brokenService.startDate &&
            parse(
              leavePeriod.brokenService.startDate,
              'yyyy-MM-dd',
              new Date()
            );
          const shouldAutoPopulateEndDate =
            !leavePeriod.brokenService.endDate &&
            leavePeriod.brokenService.scatteredDaysInd === 'N' &&
            parsedStartDate &&
            isOnOrAfterInLeaveEffectiveDate(
              parsedStartDate,
              inLeaveEffectiveDate
            );

          return {
            brokenService: {
              ...leavePeriod.brokenService,
              endDate: shouldAutoPopulateEndDate
                ? `${parsedStartDate.getFullYear()}-12-31`
                : leavePeriod.brokenService.endDate,
              openEndedLeaveInd: shouldAutoPopulateEndDate
                ? 'Y'
                : leavePeriod.brokenService.openEndedLeaveInd,
              assumedEndDateInd: shouldAutoPopulateEndDate ? 'Y' : 'N',
              scatteredDays: form165a.scatteredDaysList
                ? form165a.scatteredDaysList
                    .map(
                      scatteredDay => `${format(scatteredDay, 'yyyy-MM-dd')}`
                    )
                    .join(',')
                : '',
              priorToLeaveEarnings: form165a.contributoryEarningsPriorToLeave
                ? parseFloat(
                    form165a.contributoryEarningsPriorToLeave.replace(/,/g, '')
                  )
                : null,
              maxPayPeriods:
                form165a.payPeriodsInLeaveYear === 'other'
                  ? form165a.customPayPeriodsInLeaveYear
                  : form165a.payPeriodsInLeaveYear,
              otcftPct: form165a.percentageTimeWorked
                ? Number(form165a.percentageTimeWorked)
                : null,
              otcftNotWorkedFromDate: form165a.periodNotWorked?.from
                ? format(form165a.periodNotWorked?.from, 'yyyy-MM-dd')
                : null,
              otcftNotWorkedToDate: form165a.periodNotWorked?.to
                ? format(form165a.periodNotWorked?.to, 'yyyy-MM-dd')
                : null,
              scatteredDaysService: form165a?.scatteredDaysService
                ? parseFloat(form165a?.scatteredDaysService)
                : null
            }
          };
        })
      };
    case 2:
    case 3:
      return {
        leavePeriods: data.leavePeriods?.map(leavePeriod => ({
          purchaseCostList: leavePeriod.purchaseCostList.map(purchaseCost => ({
            ...purchaseCost,
            purchaseCostNumber: purchaseCost.purchaseCostNumber,
            service: purchaseCost.service,
            earnings: purchaseCost.earnings,
            carryForwardEarnings: purchaseCost.carryForwardEarnings,
            costAdjustedInd: purchaseCost.costAdjustedInd
          }))
        }))
      };
    default:
      return data;
  }
};

export const transformInLeaveData = (
  formData: Form165aData | Form165cData,
  currentLeavePeriod: LeavePeriod | null
) =>
  ({
    ...formData,
    dateLeaveEnded:
      currentLeavePeriod?.brokenService.assumedEndDateInd === 'Y'
        ? ''
        : formData.dateLeaveEnded,
    scatteredDaysList: currentLeavePeriod?.brokenService.scatteredDays
      ? parseScatteredDaysString(
          currentLeavePeriod?.brokenService.scatteredDays
        )
      : [],
    percentageTimeWorked:
      currentLeavePeriod?.brokenService.otcftPct?.toString() ?? '',
    periodNotWorked: {
      from: currentLeavePeriod?.brokenService?.otcftNotWorkedFromDate
        ? parse(
            currentLeavePeriod?.brokenService?.otcftNotWorkedFromDate,
            'yyyy-MM-dd',
            new Date()
          )
        : null,
      to: currentLeavePeriod?.brokenService.otcftNotWorkedToDate
        ? parse(
            currentLeavePeriod?.brokenService?.otcftNotWorkedToDate,
            'yyyy-MM-dd',
            new Date()
          )
        : null
    },
    assumedEndDateInd: currentLeavePeriod?.brokenService.assumedEndDateInd,
    scatteredDaysService: currentLeavePeriod?.brokenService.scatteredDaysService
      ? currentLeavePeriod?.brokenService.scatteredDaysService.toFixed(2)
      : null
  } as Form165aData | Form165cData);

export const transformInLeaveDataFromApi = (
  data: {
    formData: Form165cData | Form165aData;
    leavePeriods?: Array<LeavePeriod>;
    intervalNumber?: string;
  },
  currentLeavePeriod: LeavePeriod
) => ({
  ...data,
  formData: transformInLeaveData(data.formData, currentLeavePeriod)
});
