import { EapAction } from 'types';
import { MemberSearchResult, LeavePeriod, BrokenService } from 'interfaces';
import { getSubmissionStatus } from 'utils';
import { FormState } from 'containers/requestForm/common/interfaces';
import { isNull } from 'lodash';
import { form165aActionTypes } from './form165aActions';
import {
  mapForm165,
  transformInLeaveData
} from '../common/form165/utils/form165Utils';

export enum EmploymentStatus {
  CFT = 'Continuous Full-Time',
  OTCFT = 'Other than Continuous Full-Time'
}
export interface PurchaseCost {
  creditedServiceMonths: string;
  contributoryEarnings: string;
  paidFollowingYear: string;
  rppContributions: string;
  originalRppContributions: string; // hidden form value
  rcaContributions: string;
  originalRcaContributions: string; // hidden form value
  totalService?: string;
}
export interface Form165aData {
  scatteredDays: 'Y' | 'N';
  dateLeaveStarted: string;
  dateLeaveEnded: string;
  yearOfLeave: string;
  typeOfLeaveCategory: string;
  typeOfLeave: string;
  isOpenEndedLeave: 'Y' | 'N';
  dateLastAtWork: string;
  payPeriodsInLeaveYear: string;
  customPayPeriodsInLeaveYear: string;
  contributoryEarningsPriorToLeave: string;
  dateEntitledDisability: string; // hidden form value
  purchaseCostList: Array<PurchaseCost>;
  noteToMember: string;
  noteToOmers: string;
  scatteredDaysList?: Array<Date>;
  assumedEndDateInd?: 'Y' | 'N';
  percentageTimeWorked?: string;
  periodNotWorked?: { from: Date | null; to: Date | null };
  scatteredDaysService?: string;
}

export interface Form165aState extends FormState {
  form165aData: Form165aData;
  isAddLeavePeriod: boolean;
  addLeavePeriodStep: number | null;
  memberInfo: MemberSearchResult | null;
  leavePeriods: Array<LeavePeriod>;
  activeIntervalNumber: number | null;
  leavePeriodLoading: boolean;
  isEdit: boolean;
  submissionComplete: boolean;
  previousScatteredDaysList?: Array<Array<Date>>;
}

export const form165aData: Form165aData = {
  scatteredDays: 'N',
  dateLeaveStarted: '',
  dateLeaveEnded: '',
  yearOfLeave: '',
  typeOfLeaveCategory: '',
  typeOfLeave: '',
  isOpenEndedLeave: 'N',
  dateLastAtWork: '',
  payPeriodsInLeaveYear: '',
  customPayPeriodsInLeaveYear: '',
  contributoryEarningsPriorToLeave: '',
  dateEntitledDisability: '',
  purchaseCostList: [],
  noteToMember: '',
  noteToOmers: '',
  scatteredDaysList: [],
  assumedEndDateInd: 'N',
  percentageTimeWorked: '',
  periodNotWorked: { from: null, to: null },
  scatteredDaysService: ''
};

export const initialState = {
  form165aData,
  requestNumber: null,
  initLoading: false,
  isAddLeavePeriod: true,
  formStep: 1,
  addLeavePeriodStep: null,
  memberInfo: null,
  leavePeriods: [],
  activeIntervalNumber: null,
  leavePeriodLoading: false,
  isEdit: false,
  formStatus: '' as const,
  exceptions: [],
  overrideExceptions: [],
  submissionStatus: '' as const,
  submissionComplete: false,
  previousScatteredDaysList: []
};

const form165a = (
  state: Form165aState = initialState,
  action: EapAction
): Form165aState => {
  const { type, data } = action;
  switch (type) {
    case form165aActionTypes.SET_REQUEST_NUMBER_FORM_165A:
      return {
        ...initialState,
        requestNumber: data,
        initLoading: true
      };
    case form165aActionTypes.GET_FORM_165A_SUCCESS:
      return {
        ...state,
        employerNo: data.employerNo,
        requestNumber: data.requestNumber,
        leavePeriods: data.leavePeriods,
        memberInfo: data.memberInfo,
        form165aData: {
          ...initialState.form165aData,
          noteToMember: data.commentsToMember ?? '',
          noteToOmers: data.notes ?? ''
        },
        initLoading: false,
        leavePeriodLoading: false,
        formStep: 1,
        isAddLeavePeriod: data.leavePeriods.length === 0,
        addLeavePeriodStep: data.leavePeriods.length === 0 ? 1 : null,
        formStatus: '' as const,
        submissionStatus: '' as const,
        submissionComplete: false,
        exceptions: data.exceptions,
        overrideExceptions: []
      };
    case form165aActionTypes.GET_FORM_165A_FAILED:
      return {
        ...state,
        initLoading: true,
        leavePeriodLoading: false
      };
    case form165aActionTypes.CREATE_UPDATE_LEAVE_PERIOD_165A_INIT:
      return {
        ...state,
        addLeavePeriodStep: 1,
        leavePeriodLoading: false
      };
    case form165aActionTypes.CREATE_UPDATE_LEAVE_PERIOD_165A_REQUESTED:
      return {
        ...state,
        leavePeriodLoading: true,
        formStatus: 'saving' as const
      };
    case form165aActionTypes.CREATE_UPDATE_LEAVE_PERIOD_165A_SUCCESS:
      return {
        ...state,
        form165aData: data.formData,
        leavePeriods: data.leavePeriods,
        activeIntervalNumber: data.intervalNumber,
        leavePeriodLoading: false,
        formStatus: 'success' as const
      };
    case form165aActionTypes.CREATE_UPDATE_LEAVE_PERIOD_165A_FAILED:
      return {
        ...state,
        leavePeriodLoading: false,
        formStatus: 'error' as const
      };
    case form165aActionTypes.EDIT_LEAVE_PERIOD_165A: {
      const activeLeavePeriod =
        (data
          ? state.leavePeriods.find(
              (leavePeriod: { brokenService: BrokenService }) =>
                leavePeriod.brokenService.intervalNumber === data
            )
          : null) ?? null;

      return {
        ...state,
        isAddLeavePeriod: true,
        activeIntervalNumber: data,
        addLeavePeriodStep: 1,
        isEdit: true,
        form165aData: mapForm165(activeLeavePeriod, {
          noteToMember: !isNull(data) ? null : state?.commentsToMember ?? '',
          noteToOmers: !isNull(data) ? null : state?.notes ?? ''
        })
      };
    }
    case form165aActionTypes.EDIT_IN_LEAVE_PERIOD_165A: {
      const activeLeavePeriod =
        (data
          ? state.leavePeriods.find(
              (leavePeriod: { brokenService: BrokenService }) =>
                leavePeriod.brokenService.intervalNumber === data
            )
          : null) ?? null;

      return {
        ...state,
        isAddLeavePeriod: true,
        activeIntervalNumber: data,
        addLeavePeriodStep: 1,
        isEdit: true,
        form165aData: transformInLeaveData(
          mapForm165(activeLeavePeriod, {
            noteToMember: !isNull(data) ? null : state?.commentsToMember ?? '',
            noteToOmers: !isNull(data) ? null : state?.notes ?? ''
          }),
          activeLeavePeriod
        )
      };
    }
    case form165aActionTypes.SET_IS_ADD_LEAVE_PERIOD_165A:
      return {
        ...state,
        isAddLeavePeriod: data,
        leavePeriodLoading: false,
        activeIntervalNumber: data ? state.activeIntervalNumber : null,
        addLeavePeriodStep: data ? 1 : null,
        isEdit: false
      };
    case form165aActionTypes.SET_ADD_PERIOD_STEP_165A:
      return {
        ...state,
        addLeavePeriodStep: data
      };
    case form165aActionTypes.DELETE_LEAVE_PERIOD_165A_SUCCESS:
      return {
        ...state,
        leavePeriods: data,
        isAddLeavePeriod: data.length === 0,
        addLeavePeriodStep: data.length === 0 ? 1 : null,
        form165aData: {
          ...state.form165aData,
          noteToMember: state.form165aData.noteToMember ?? '',
          noteToOmers: state.form165aData.noteToOmers ?? ''
        }
      };
    case form165aActionTypes.SAVE_FORM_165A_REQUESTED:
    case form165aActionTypes.SAVE_AND_REVIEW_FORM_165A_REQUESTED:
      return {
        ...state,
        formStatus: 'saving' as const
      };
    case form165aActionTypes.SAVE_FORM_165A_SUCCESS:
      return {
        ...state,
        formStatus: 'success' as const,
        form165aData: {
          ...state.form165aData,
          noteToMember: data.commentsToMember ?? '',
          noteToOmers: data.notes ?? ''
        },
        commentsToMember: data.commentsToMember ?? '',
        notes: data.notes ?? ''
      };
    case form165aActionTypes.SAVE_FORM_165A_FAILED:
      return {
        ...state,
        formStatus: 'error' as const
      };
    case form165aActionTypes.CLEAR_FORM_165A_STATUS:
      return {
        ...state,
        formStatus: '' as const
      };
    case form165aActionTypes.SET_FORM_165A_STEP:
      return {
        ...state,
        formStep: data
      };
    case form165aActionTypes.SET_STORE_DATA_FORM_165A:
      return {
        ...state,
        form165aData: data
      };
    case form165aActionTypes.SUBMIT_FORM_165A_SUCCESS:
      return {
        ...state,
        exceptions: !data.exceptions ? [] : data.exceptions,
        overrideExceptions: [],
        submissionStatus: getSubmissionStatus(data, data.exceptions),
        submissionComplete:
          getSubmissionStatus(data, data.exceptions) === 'success',
        formStep:
          getSubmissionStatus(data, data.exceptions) === 'success' ? 3 : 2,
        initLoading: true,
        pdfs: data.attachments
      };
    case form165aActionTypes.OVERRIDE_FORM_165A_EXCEPTIONS:
      return {
        ...state,
        overrideExceptions: data
      };
    case form165aActionTypes.SET_FORM_165A_SUBMIT_STATUS:
      return {
        ...state,
        submissionStatus: data
      };
    case form165aActionTypes.SET_FORM_165A_SAVING_STATUS:
      return {
        ...state,
        formStatus: data
      };
    case form165aActionTypes.DELETE_FORM_165A_SUCCESS:
      return {
        ...initialState,
        formStatus: 'deleted' as const
      };
    case form165aActionTypes.SET_PREV_SCATTERED_DAYS_LIST:
      return {
        ...state,
        previousScatteredDaysList: data
      };
    default:
      return state;
  }
};

export default form165a;
